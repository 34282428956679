import React from "react";
const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/IntroAnimation")
);
const IntroAnimationPage = () => {
  const isSSR = typeof window === "undefined";

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy />
        </React.Suspense>
      )}
    </>
  );
};
export default IntroAnimationPage;
